(function ($) {
    var refreshIntervals = [];
    var scrollIntervals = [];
    var scrollTimeouts = [];
    function _destroy() {
        for (var i = 0; i < refreshIntervals.length; i++) {
            clearInterval(refreshIntervals[i]);
        }
        refreshIntervals = [];
        for (var i = 0; i < scrollIntervals.length; i++) {
            clearInterval(scrollIntervals[i]);
        }
        scrollIntervals = [];

        for (var i = 0; i < scrollTimeouts.length; i++) {
            clearTimeout(scrollTimeouts[i]);
        }
        scrollTimeouts = [];
        $(this).find('.vpar-leaderboardInner').remove();
    }

    $.fn.leaderboard = function (options) {
        return this.each(function () {
            if ($(this).find('.vpar-leaderboardInner').length > 0) {
                $(this).find('.vpar-leaderboardInner').remove();
            }
            var settings = getSettings(this, options);
            if ((settings.competitionId > 0) || (settings.venueId > 0) || (settings.societyId > 0) || (settings.profileId > 0)) {
                this.container = $(this);

                this.options = settings;

                this.state = {
                    leaderboardIndex: 0,
                    animating: false,
                    columnWidths: new Array(),
                    oldData: new Array(),
                    currentData: new Array(),
                    verticleOffset: 0,
                    intervalId: 0,
                    scrollIntervalId: 0,
                    scrollTopWaitId: 0,
                    scrollBottomWaitId: 0,
                    rowOffset: 0,
                    rowIndex: 0,
                    switchInterval: 0,
                    isScrolling: false,
                    needsDataRefresh: false,
                    lastDataRefreshTime: null,
                    hiddenRowsTop: 0,
                    hiddenRowsBottom: 0,
                    headerRowHeight: 0,
                    topWaitStartTime: 0,
                    bottomWaitStartTime: 0,
                    currentScrollMode: 'build'
                };

                this.configs = new Array();

                $(window).on('resize', function () {
                    updateWidth();
                });
                this.destroy = function () {
                    _destroy();
                    return $(this);
                };

                return init(this);
            }
            else return false;
        });
    };

    $.fn.leaderboard.defaults = {
        societyId: 0,               // The society ID                                   //
        venueId: 0,                 // The venue ID                                     // One of these three settings must be > 0
        competitionId: 0,           // The competition ID                               //
        profileId: 0,               // The profile ID                               //
        roundId: 0,                 // The round ID - only used with competition ID        
        leaderboards: [],           // The leaderboard name and/or IDs to show
        refreshRate: 30000,         // The data refresh rate
        height: 0,                  // The height of the leaderboard, if 0 then will be 100%
        scrollbar: false,           // Show a scrollbar when height is passed
        scrollRate: 1000,           // If scrollbar = false, the rate at which items will auto scroll
        scrollTopWait: 5000,        // If scrollbar = false, the time to wait on the top item before auto scroll
        thumbnailHeight: 45,        // The height of the thumbnail image, if no thumbnail is required send 0
        cycleLeaderboards: false,   // Auto cycle leaderboards
        cycleRate: 120000,          // Auto cycle leaderboard rate
        title: true,               // Show leaderboard title
        navigation: true,          // Show navigation
        autoHideColumns: true,      // When width is small, auto hide non essential columns
        allowMultipleLines: true,   // Allow multiple lines within a data column (e.g. profile column, containing Title & HCP)
        canEditScorecards: false,   // Allow scorecards to be edited
        showSlimVersion: false,     // Show the slim version of the leaderboard
        externalTitleSelector: '',  // The jquery selector of a leaderboard title external to the leaderboard control
        clearExistingRefreshIntervals: false,
        scoresDomain: 'http://www.vpar.com',
        altnewlbfeedhost: '',
        maxItems: 0                 // The maximum number of items to return
    };

    function getSettings(_this, options) {
        var settings = null;

        $.fn.leaderboard.defaults.leaderboards = [];
        $.fn.leaderboard.defaults.canEditScorecards = false;
        $.fn.leaderboard.defaults.showSlimVersion = false;
        $.fn.leaderboard.defaults.roundId = 0;
        $.fn.leaderboard.defaults.competitionId = 0;
        $.fn.leaderboard.defaults.societyId = 0;
        $.fn.leaderboard.defaults.venueId = 0;
        $.fn.leaderboard.defaults.profileId = 0;
        $.fn.leaderboard.defaults.title = false;
        $.fn.leaderboard.defaults.thumbnailHeight = 45;
        $.fn.leaderboard.defaults.externalTitleSelector = '';
        $.fn.leaderboard.defaults.clearExistingRefreshIntervals = false;
        $.fn.leaderboard.defaults.navigation = false;
        $.fn.leaderboard.defaults.refreshRate = 30000;
        $.fn.leaderboard.defaults.scoresDomain = 'http://www.vpar.com';
        $.fn.leaderboard.defaults.altnewlbfeedhost = '';
        $.fn.leaderboard.defaults.maxItems = 0;

        if (options != null) {
            settings = $.extend({}, $.fn.leaderboard.defaults, options);
        }
        else if (_this.options != null) {
            settings = _this.options;
        }
        else {
            settings = $.fn.leaderboard.defaults;
        }

        if (settings != null && settings.refreshRate != null && settings.refreshRate < 30000 && settings.refreshRate != 0) { settings.refreshRate = 30000; }

        // Check the element for any data tags and replace the default settings and the options passed in
        var dataObjects = $(_this).data();
        if (dataObjects != undefined) {
            if (dataObjects.societyId != undefined && typeof dataObjects.societyId === 'number') { settings.societyId = dataObjects.societyId; }
            if (dataObjects.competitionId != undefined && typeof dataObjects.competitionId === 'number') { settings.competitionId = dataObjects.competitionId; }
            if (dataObjects.roundId != undefined && typeof dataObjects.roundId === 'number') { settings.roundId = dataObjects.roundId; }
            if (dataObjects.venueId != undefined && typeof dataObjects.venueId === 'number') { settings.venueId = dataObjects.venueId; }
            if (dataObjects.profileId != undefined && typeof dataObjects.profileId === 'number') { settings.profileId = dataObjects.profileId; }
            if (dataObjects.leaderboards != undefined && (settings.leaderboards.length <= 0)) { settings.leaderboards = (isNaN(dataObjects.leaderboards) ? dataObjects.leaderboards.split(',') : dataObjects.leaderboards); }
            if (dataObjects.refreshRate != undefined && typeof dataObjects.refreshRate === 'number' && (dataObjects.refreshRate >= settings.refreshRate || dataObjects.refreshRate == 0)) { settings.refreshRate = dataObjects.refreshRate; }
            if (dataObjects.height != undefined && typeof dataObjects.height === 'number' && settings.height == 0) { settings.height = dataObjects.height; }
            if (dataObjects.scrollbar != undefined && typeof dataObjects.scrollbar === 'boolean' && settings.scrollbar == $.fn.leaderboard.defaults.scrollbar) { settings.scrollbar = dataObjects.scrollbar; }
            if (dataObjects.scrollRate != undefined && typeof dataObjects.scrollRate === 'number' && dataObjects.scrollRate >= settings.scrollRate) { settings.scrollRate = dataObjects.scrollRate; }
            if (dataObjects.scrollTopWait != undefined && typeof dataObjects.scrollTopWait === 'number' && dataObjects.scrollTopWait >= settings.scrollTopWait) { if (options != null && options.scrollTopWait > 0) { settings.scrollTopWait = options.scrollTopWait; } else { settings.scrollTopWait = dataObjects.scrollTopWait; } }
            if (dataObjects.thumbnailHeight != undefined && typeof dataObjects.thumbnailHeight === 'number') { settings.thumbnailHeight = 45; } //dataObjects.thumbnailHeight
            if (dataObjects.cycleLeaderboards != undefined && typeof dataObjects.cycleLeaderboards === 'boolean') { settings.cycleLeaderboards = dataObjects.cycleLeaderboards; }
            if (dataObjects.cycleRate != undefined && typeof dataObjects.cycleRate === 'number') { settings.cycleRate = dataObjects.cycleRate; }
            if (dataObjects.title != undefined && typeof dataObjects.title === 'boolean') { settings.title = dataObjects.title; }
            if (dataObjects.navigation != undefined && typeof dataObjects.navigation === 'boolean') { settings.navigation = dataObjects.navigation; }
            if (dataObjects.autoHideColumns != undefined && typeof dataObjects.autoHideColumns === 'boolean') { settings.autoHideColumns = dataObjects.autoHideColumns; }
            if (dataObjects.allowMultipleLines != undefined && typeof dataObjects.allowMultipleLines === 'boolean') { settings.allowMultipleLines = dataObjects.allowMultipleLines; }
            if (dataObjects.canEditScorecards != undefined && typeof dataObjects.canEditScorecards === 'boolean') { settings.canEditScorecards = dataObjects.canEditScorecards; }
            if (dataObjects.showSlimVersion != undefined && typeof dataObjects.showSlimVersion === 'boolean') { settings.showSlimVersion = dataObjects.showSlimVersion; }
            if (dataObjects.externalTitleSelector != undefined && typeof dataObjects.externalTitleSelector === 'string') { settings.externalTitleSelector = dataObjects.externalTitleSelector; }
            if (dataObjects.clearExistingRefreshIntervals != undefined && typeof dataObjects.clearExistingRefreshIntervals === 'boolean') { settings.clearExistingRefreshIntervals = dataObjects.clearExistingRefreshIntervals; }
            if (dataObjects.scoresDomain != undefined && typeof dataObjects.scoresDomain === 'string') { settings.scoresDomain = dataObjects.scoresDomain; }
            if (dataObjects.altnewlbfeedhost != undefined && typeof dataObjects.altnewlbfeedhost === 'string') { settings.altnewlbfeedhost = dataObjects.altnewlbfeedhost; }
            if (dataObjects.maxItems != undefined && typeof dataObjects.maxItems === 'number') { settings.maxItems = dataObjects.maxItems; }
        }

        return settings;
    }

    function init(_this) {
        if (_this.configs == undefined || _this.configs.length == 0) {
            return loadConfigs(_this, processConfig);
        } else {
            processConfig(_this);
            return true;
        }
    }
    function processConfig(_this) {
        if (_this.options.clearExistingRefreshIntervals && refreshIntervals != undefined && refreshIntervals.length > 0) {
            for (var i = 0; i < refreshIntervals.length; i++) {
                clearInterval(refreshIntervals[i]);
            }
        }

        build(_this);

        start(_this);

        if (_this.configs.length > 1 && _this.options.cycleLeaderboards) {
            _this.state.switchInterval = setInterval(function () {
                if (_this.state.scrollTopWaitId > 0) { window.clearTimeout(_this.state.scrollTopWaitId); _this.state.scrollTopWaitId = 0; }
                if (_this.state.scrollIntervalId > 0) { window.clearInterval(_this.state.scrollIntervalId); _this.state.scrollIntervalId = 0; }
                _this.state.hiddenRowsTop = 0;
                _this.state.hiddenRowsBottom = 0;
                _this.state.rowOffset = 0;
                switchLeaderboard(_this, null, true);
            }, _this.options.cycleRate);
            refreshIntervals.push(_this.state.switchInterval);
        }
    }

    function currentConfig(_this) {
        return _this.configs[_this.state.leaderboardIndex];
    }

    function loadConfigs(_this, successCallBack) {
        //alert(_this.options.scoresDomain + '/services/request.aspx?action=getLeaderboards&SocietyID=' + _this.options.societyId + '&CompetitionID=' + _this.options.competitionId + '&RoundID=' + _this.options.roundId + '&VenueID=' + _this.options.venueId + '&ProfileID=' + _this.options.profileId + '&Leaderboards=' + (isNaN(_this.options.leaderboards) ? _this.options.leaderboards.join('|') : _this.options.leaderboards) + '&AllowMultipleLines=' + _this.options.allowMultipleLines + '&CanEditScorecards=' + _this.options.canEditScorecards + '&ShowSlimVersion=' + _this.options.showSlimVersion + '&maxItems=' + _this.options.maxItems + '&return=3');
        var url = _this.options.scoresDomain + '/services/request.aspx?action=getLeaderboards&SocietyID=' + _this.options.societyId + '&CompetitionID=' + _this.options.competitionId + '&RoundID=' + _this.options.roundId + '&VenueID=' + _this.options.venueId + '&ProfileID=' + _this.options.profileId + '&Leaderboards=' + (isNaN(_this.options.leaderboards) ? _this.options.leaderboards.join('|') : _this.options.leaderboards) + '&AllowMultipleLines=' + _this.options.allowMultipleLines + '&CanEditScorecards=' + _this.options.canEditScorecards + '&ShowSlimVersion=' + _this.options.showSlimVersion + '&maxItems=' + _this.options.maxItems + '&return=3' + '&altnewlbfeedhost=' + _this.options.altnewlbfeedhost;
        $.ajax({
            type: 'GET',
            url: url,
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            crossDomain: true,
            async: true,
            success: function (data) {
                console.log("Leaderboard Config data loaded");
                if (data.Response.leaderboardConfigs != null) {
                    if (data.Response.leaderboardConfigs.leaderboardConfig != undefined && data.Response.leaderboardConfigs.leaderboardConfig.length != undefined) {
                        for (var i = 0; i < data.Response.leaderboardConfigs.leaderboardConfig.length; i++) {
                            _this.configs.push(readConfig(data.Response.leaderboardConfigs.leaderboardConfig[i]));
                        }
                    }
                    else if (data.Response.leaderboardConfigs.leaderboardConfig != undefined) {
                        _this.configs.push(readConfig(data.Response.leaderboardConfigs.leaderboardConfig));
                    }
                }
                successCallBack(_this);
                return true;
            },
            error: function (xhr, err) {
                //alert("readyState: " + xhr.readyState + "\nstatus: " + xhr.status + "\nresponseText: " + xhr.responseText);
                return false;
            }
        });
    }

    function readConfig(config) {
        var leaderboardConfig = { feedUrl: '', title: '', columns: new Array() };
        leaderboardConfig.feedUrl = config.feedURL;
        leaderboardConfig.title = config.title;
        if (config.columns) {
            if (config.columns.column.length != undefined) {
                for (var i = 0; i < config.columns.column.length; i++) {
                    leaderboardConfig.columns.push(readColumnConfig(config.columns.column[i]));
                }
            }
            else { leaderboardConfig.columns.push(readColumnConfig(config.columns.column)); }
        }
        return leaderboardConfig;
    }

    function readColumnConfig(column) {
        var leaderboardColumn = { index: 0, headerText: '', dataItem: '', dataTemplate: '', isVisible: true, isThumbnailColumn: false, textAlign: 'left', highlight: false, hideIndex: 64 }
        leaderboardColumn.index = column.index;
        leaderboardColumn.headerText = column.headerText;
        leaderboardColumn.dataItem = column.dataItem;
        leaderboardColumn.dataTemplate = column.dataTemplate;
        leaderboardColumn.isVisible = column.isVisible.toLowerCase() === 'true';
        leaderboardColumn.isThumbnailColumn = column.isThumbnailColumn.toLowerCase() === 'true';
        leaderboardColumn.textAlign = column.textAlign;
        leaderboardColumn.highlight = column.highlight.toLowerCase() === 'true';
        leaderboardColumn.hideIndex = (!isNaN(parseInt(column.hideIndex)) ? parseInt(column.hideIndex) : 255);
        return leaderboardColumn;
    }

    function build(_this) {
        _this.container.append('<div class="vpar-leaderboardInner"' + (_this.options.height > 0 ? ' style="height: ' + _this.options.height + 'px; ' + (_this.options.scrollbar ? 'overflow-y: scroll; position: relative;' : 'overflow: hidden;') + '"' : '') + '><div style="display: none;" class="vpar-leaderboardOverlay"></div></div>');

        if (_this.configs != null) {
            if (currentConfig(_this) != undefined) {
                //if (_this.options.title) {
                //    if (_this.options.navigation && !_this.options.cycleLeaderboards && _this.configs.length > 1) { }
                //    else { _this.container.find('.vpar-leaderboardInner').append('<h3>' + currentConfig(_this).title + '</h3>'); }
                //}

                if (_this.options.externalTitleSelector != '') {
                    $(_this.options.externalTitleSelector).html(currentConfig(_this).title);
                }

                buildNavigation(_this);

                $('.leaderboardNext').unbind();
                $('.leaderboardNext').on('click', function () {
                    _this.container.find('.vpar-leaderboardOverlay').fadeIn(400, function () {
                        switchLeaderboard(_this, null, false);
                    });
                });

                for (var i = 0; i < _this.configs.length; i++) {
                    buildTableContainer(_this, i);
                }
            }
        }
    }

    function buildNavigation(_this) {
        if (_this.options.navigation && !_this.options.cycleLeaderboards && _this.configs.length > 0) {
            var nav = null;

            if (_this.options.title) {
                _this.container.find('.vpar-leaderboardInner').append('<div class="vpar-leaderboardNav-titles"></div>');
                nav = $(_this.container.find('.vpar-leaderboardNav-titles'));
            }
            else {
                _this.container.find('.vpar-leaderboardInner').append('<div class="vpar-leaderboardNav"><ul></ul></div>');
                nav = $(_this.container.find('.vpar-leaderboardNav'));
            }

            for (var i = 0; i < _this.configs.length; i++) {
                if (_this.options.title) {
                    nav.append('<h3><a href="javascript:void(0);" data-leaderboard-index="' + i + '" title="">' + _this.configs[i].title + '</a></h3>');
                }
                else {
                    nav.find('ul').append('<li><a href="javascript:void(0);" data-leaderboard-index="' + i + '" title="' + _this.configs[i].title + ' Leaderboard"><span></span></a></li>');
                }
            }

            nav.find('a').on('click', function () {
                var lbIndex = parseInt($(this).attr('data-leaderboard-index'));
                _this.container.find('.vpar-leaderboardOverlay').fadeIn(400, function () {
                    switchLeaderboard(_this, lbIndex, false);
                });
            });

            if (!_this.options.title) { nav.css('margin-left', '-' + (nav.width() / 2) + 'px'); }

            updateNavigation(_this);
        }
    }

    function updateNavigation(_this) {
        if (_this.options.navigation && !_this.options.cycleLeaderboards && _this.configs.length > 0) {
            var nav = null;
            if (_this.options.title) { nav = $(_this.container.find('.vpar-leaderboardNav-titles')); }
            else { nav = $(_this.container.find('.vpar-leaderboardNav')); }
            nav.find('a').removeClass('active');
            nav.find('[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]').addClass('active');
        }
    }

    function buildTableContainer(_this, leaderboardIndex) {
        var containerTable = '<table class="vpar-leaderboardTable" data-leaderboard-index="' + leaderboardIndex + '"><thead><tr>';
        for (var i = 0; i < _this.configs[leaderboardIndex].columns.length; i++) {
            if (_this.configs[leaderboardIndex].columns[i].isVisible) {
                containerTable = containerTable + '<th style="text-align: ' + _this.configs[leaderboardIndex].columns[i].textAlign + ';' + (_this.configs[leaderboardIndex].columns[i].isThumbnailColumn ? ' width: ' + _this.options.thumbnailHeight + 'px;' : '') + '" class="' + (_this.configs[leaderboardIndex].columns[i].highlight ? 'hiLite ' : '') + (_this.configs[leaderboardIndex].columns[i].isThumbnailColumn ? 'col-thumbnail' : '') + '" data-hide-index="' + _this.configs[leaderboardIndex].columns[i].hideIndex + '">' + (_this.configs[leaderboardIndex].columns[i].headerText != null ? _this.configs[leaderboardIndex].columns[i].headerText : '') + '</th>';
            }
        }
        if (_this.options.scrollbar && _this.options.height > 0) {
            containerTable = containerTable + '<th style="max-width: 20px;"></th>';
        }

        containerTable = containerTable + '</tr></thead><tbody></tbody></table>';
        _this.container.find('.vpar-leaderboardInner').append(containerTable);
        var table = _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + leaderboardIndex + '"]');
        table.hide();
        table.width(_this.container.width());
    }

    function start(_this) {
        if (currentConfig(_this) != undefined) {
            getData(_this);
            if (_this.options.refreshRate > 0) {
                _this.state.intervalId = setInterval(function () { getData(_this); }, _this.options.refreshRate);
                refreshIntervals.push(_this.state.intervalId);
            }
        }
        return true;
    }

    function getData(_this) {
        if (_this.state.animating) {
            setTimeout(getData(_this), _this.options.scrollRate);
        }
        else {
            var t = new Date();
            var oldTime = new Date(_this.state.lastDataRefreshTime);
            if (_this.state.lastDataRefreshTime == null || Math.ceil(t.getTime() - oldTime.getTime()) >= _this.options.refreshRate) {
                _this.state.lastDataRefreshTime = t.toUTCString();

                var feedUrl = currentConfig(_this).feedUrl + '&ThumbnailHeight=' + _this.options.thumbnailHeight + '&return=3';

                if (_this.options.showSlimVersion) {
                    var feedIndex = feedUrl.indexOf('PageSize=');
                    if (feedIndex > -1) {
                        feedUrl = replaceBetween_LB(feedUrl, feedIndex + 9, feedUrl.indexOf('&', feedIndex), '10');
                    }
                }

                for (var i = 0; i < scrollIntervals.length; i++) {
                    clearInterval(scrollIntervals[i]);
                }
                scrollIntervals = [];

                for (var i = 0; i < scrollTimeouts.length; i++) {
                    clearTimeout(scrollTimeouts[i]);
                }
                scrollTimeouts = [];

                var table = _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]');
                $(table).find('thead, tbody').css('position', '');

                $.ajax({
                    type: 'GET',
                    url: feedUrl.replace('=24', '=36') + '&CallerID=1',
                    contentType: 'application/json; charset=utf-8',
                    dataType: 'json',
                    crossDomain: true,
                    async: true,
                    success: function (data) {
                        console.log("Leaderboard Data loaded");
                        if (data.Response != undefined) {
                            var topNode = 'FM';
                            var isNonStandard = false;

                            if (data.Response.L != undefined) {
                                if (data.Response.L['@LeaderboardTypeID'] == '2') { topNode = 'T'; } // Team
                                else if (data.Response.L['@LeaderboardTypeID'] == '6') { topNode = 'H'; } // Tournament Skins
                                else if (data.Response.L['@LeaderboardTypeID'] == '3') { isNonStandard = true; } // Matchplay
                                else if (data.Response.L['@LeaderboardTypeID'] == '5') { isNonStandard = true; } // Skins
                            }
                            else if (data.Response.LeaderboardType != undefined) {
                                if (data.Response.LeaderboardType == '3' || data.Response.LeaderboardType == '5') { isNonStandard = true; }
                            }

                            if (data.Response.L != undefined) {
                                var isFirstBuild = _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]').find('tr').length <= 1;

                                if (!isNonStandard) {

                                    //if (!_this.state.isScrolling) {
                                    _this.state.animating = true;
                                    if (isFirstBuild) {
                                        buildTable(_this, data, topNode, isNonStandard);
                                    }
                                    else {
                                        updateTable(_this, data, topNode, isNonStandard);
                                    }
                                    _this.state.animating = false;
                                    updateWidth();

                                    calculateHiddenRows(_this);

                                    if (!_this.options.scrollbar && _this.options.height > 0 && ($(table).height() > _this.options.height || (_this.state.hiddenRowsBottom + _this.state.hiddenRowsTop) > 0)) {
                                        var currentDate = new Date();
                                        if (_this.state.currentScrollMode == "topWait") {
                                            if (_this.state.topWaitStartTime != 0) {
                                                if (((_this.state.topWaitStartTime.getTime() + _this.options.scrollTopWait) - currentDate.getTime()) > 0) {
                                                    var waitTime = (_this.state.topWaitStartTime.getTime() + _this.options.scrollTopWait) - currentDate.getTime();
                                                    _this.state.scrollTopWaitId = setTimeout(function () { scrollData(_this); }, ((_this.state.topWaitStartTime.getTime() + _this.options.scrollTopWait) - currentDate.getTime()));
                                                    scrollTimeouts.push(_this.state.scrollTopWaitId);
                                                }
                                                else {
                                                    scrollRows(_this);
                                                }
                                            }
                                            else {
                                                setupScroll(_this);
                                            }
                                        }
                                        else if (_this.state.currentScrollMode == "bottomWait") {
                                            if (_this.state.bottomWaitStartTime != 0) {
                                                if (((_this.state.bottomWaitStartTime.getTime() + (_this.options.scrollTopWait / 2)) - currentDate.getTime()) > 0) {
                                                    var waitTime = (_this.state.bottomWaitStartTime.getTime() + (_this.options.scrollTopWait / 2)) - currentDate.getTime();
                                                    _this.state.scrollBottomWaitId = setTimeout(function () {
                                                        endScroll(_this);
                                                    }, ((_this.state.bottomWaitStartTime.getTime() + (_this.options.scrollTopWait / 2)) - currentDate.getTime()));
                                                    scrollTimeouts.push(_this.state.scrollBottomWaitId);
                                                }
                                                else {
                                                    scrollRows(_this);
                                                }
                                            }
                                            else { scrollRows(_this); }
                                        }
                                        else if (_this.state.currentScrollMode == "scrollingRows") {
                                            scrollRows(_this);
                                        }
                                        else if (_this.state.currentScrollMode == "build") {
                                            _this.state.currentScrollMode == "built";
                                            setupScroll(_this);
                                        }
                                        else {
                                            alert("Error");
                                        }
                                    }
                                    //}
                                    //else { _this.state.needsDataRefresh = true; }
                                }
                                else {
                                    if (!_this.state.isScrolling) {

                                        $(table).find('thead').remove();
                                        $(table).find('tbody tr').remove();
                                        $(table).find('tbody').append('<tr><td style="border-top: none; padding: 0px;">' + $('<div/>').html(data.Response.L.HTMLContent).html() + '</td></tr>');
                                        $(table).show();

                                        if (!_this.options.scrollbar && _this.options.height > 0 && $(table).height() > _this.options.height) {
                                            setupScroll(_this);
                                        }
                                    }
                                    else { _this.state.needsDataRefresh = true; }
                                }
                            }
                        }
                    },
                    error: function (xhr, err) {
                        //alert("readyState: " + xhr.readyState + "\nstatus: " + xhr.status + "\nresponseText: " + xhr.responseText);
                    }
                });
            }
        }
    }

    function buildTable(_this, data, topNode, isNonStandard) {
        _this.state.oldData = _this.state.currentData.concat();
        _this.state.currentData = new Array();

        var LTopNode = data.Response.L[topNode];
        var isProfileLB = false;
        if (data.Response.L != undefined) { isProfileLB = data.Response.L['@LeaderboardTypeID'] == '11' || data.Response.L['@LeaderboardTypeID'] == '9' }
        else if (data.Response.LeaderboardType != undefined) { isProfileLB = data.Response.LeaderboardType == '11' || data.Response.LeaderboardType == '9' }

        var table = _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]');

        if (!isNonStandard && LTopNode != null) {
            var debugStr = '';
            var start = new Date().getTime();

            if (LTopNode.length != undefined) {
                var rows = '';
                for (var i = 0; i < LTopNode.length; i++) {
                    var row = '<tr class="row-' + LTopNode[i].ID + (LTopNode[i].RoundEncryptedID == undefined ? ' nonLink"' : '') + '" style="position: absolute;">';
                    _this.state.currentData.push(new Object());
                    _this.state.currentData[_this.state.currentData.length - 1].id = LTopNode[i].ID;
                    for (var k = 0; k < currentConfig(_this).columns.length; k++) {
                        var col = currentConfig(_this).columns[k];
                        if (col.isVisible) {
                            row = row + '<td style="text-align: ' + col.textAlign + ';' + (col.isThumbnailColumn ? ' width: ' + _this.options.thumbnailHeight + 'px; height: ' + _this.options.thumbnailHeight + 'px;' : '') + '" class="' + (col.highlight ? 'hiLite ' : (isProfileLB && LTopNode[i].PID != undefined && LTopNode[i].PID == _this.options.profileId ? 'hiLiteRow ' : '')) + (col.isThumbnailColumn ? 'col-thumbnail' : '') + '">' + readDataRow(_this, data, topNode, i, k) + '</td>';
                        }
                    }
                    if (_this.options.scrollbar && _this.options.height > 0) {
                        row = row + '<td></td>';
                    }
                    row = row + '</tr>';
                    rows += row;
                }

                table.append(rows);

                for (var i = 0; i < LTopNode.length; i++) {
                    _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"] .row-' + LTopNode[i].ID).click({ _this: _this, encryptedId: LTopNode[i].EncryptedID, roundEncryptedId: LTopNode[i].RoundEncryptedID, leaderboardTypeId: data.Response.L['@LeaderboardTypeID'], leaderboardConfigText: currentConfig(_this).title }, getRowLink);
                }

            }
            else {
                var row = '<tr class="row-' + LTopNode.ID + (LTopNode.RoundEncryptedID == undefined ? ' nonLink"' : '') + '" style="position: absolute;">';
                _this.state.currentData.push(new Object());
                _this.state.currentData[_this.state.currentData.length - 1].id = LTopNode.ID;
                for (var k = 0; k < currentConfig(_this).columns.length; k++) {
                    var col = currentConfig(_this).columns[k];
                    if (col.isVisible) {
                        row = row + '<td style="text-align: ' + col.textAlign + ';' + (col.isThumbnailColumn ? ' width: ' + _this.options.thumbnailHeight + 'px; height: ' + _this.options.thumbnailHeight + 'px;' : '') + '" class="' + (col.highlight ? 'hiLite ' : (isProfileLB && LTopNode.PID != undefined && LTopNode.PID == _this.options.profileId ? 'hiLiteRow ' : '')) + (col.isThumbnailColumn ? 'col-thumbnail' : '') + '">' + readDataRow(_this, data, topNode, null, k) + '</td>';
                    }
                }
                if (_this.options.scrollbar && _this.options.height > 0) {
                    row = row + '<td></td>';
                }
                row = row + '</tr>';
                table.append(row);
                _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"] .row-' + LTopNode.ID).click({ _this: _this, encryptedId: LTopNode.EncryptedID, roundEncryptedId: LTopNode.RoundEncryptedID, leaderboardTypeId: data.Response.L['@LeaderboardTypeID'], leaderboardConfigText: currentConfig(_this).title }, getRowLink);
            }

            var end = new Date().getTime();

            $(table).show();

            // Set table to position relative
            $(table).css('position', 'relative');

            // Set each row's height

            $(table).height($(table).quickOuterHeight());
            _this.state.verticleOffset = $(_this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]').find('th')).quickOuterHeight();

            for (var i = 0; i < _this.state.currentData.length; i++) {
                var row = _this.container.find('.row-' + _this.state.currentData[i].id);
                var rowHeight = 45;
                if (row != null) {
                    //var minHeight = $(row).find('td, th').quickOuterHeight();
                    //var trOuterHeight = $(row).quickOuterHeight();
                    ////$(row).css({ 'position': 'absolute' });
                    //rowHeight = (trOuterHeight > minHeight ? trOuterHeight : minHeight);
                    $(row).css({
                        'height': rowHeight + 'px',
                        'top': _this.state.verticleOffset + 'px'
                    });
                    //$(row).height(rowHeight).css('top', _this.state.verticleOffset + 'px').css('position', 'absolute');
                    _this.state.verticleOffset += rowHeight;
                }
                _this.state.currentData[i].height = rowHeight;
            }

            end = new Date().getTime();
            debugStr += '<br />Row Each: ' + (end - start);

            $(table).height(_this.state.verticleOffset);
            _this.container.height($(_this.container).quickOuterHeight());

            debugStr += '<br />For Loop: ' + (end - start);
            start = new Date().getTime();

            _this.container.height($('.vpar-leaderboardInner').quickOuterHeight());

        }
        else if (isNonStandard) {

            var isMatchplay = false;

            if ((data.Response.L != undefined && data.Response.L['@LeaderboardTypeID'] == '3') || (data.Response.LeaderboardType != undefined && data.Response.LeaderboardType == 3)) {
                isMatchplay = true;
            }

            if (isMatchplay) {
                var headerRow = $(table).find('thead tr');

                if (data.Response.Teams != undefined && data.Response.Teams.Team != undefined && data.Response.Teams.Team.length == 2) {
                    var team1 = data.Response.Teams.Team[0];
                    var team2 = data.Response.Teams.Team[1];
                    var predicted = data.Response.Teams['@Predicted'];


                    if (team1 != undefined && team2 != undefined) {
                        headerRow.append('<td id="team1HeadTitle" style="text-align: right; background-color: #' + team1.Colour + ';">' + team1.Title + '</td><td id="team1HeadScore" style="text-align: center; background-color: #' + team1.Colour + ';">' + team1.Pts + '</td><td id="headCentre"></td><td id="team2HeadScore" style="text-align: center; background-color: #' + team2.Colour + ';">' + team2.Pts + '</td><td id="team2HeadTitle" style="text-align: left; background-color: #' + team2.Colour + ';">' + team2.Title + '</td>');

                        if (predicted.indexOf('v') > -1) {
                            var predictedTeam1 = data.Response.Teams['@PredictedTeam1'];
                            var predictedTeam2 = data.Response.Teams['@PredictedTeam2'];
                            var centreTable = '<table style="width: 100%;"><tr><td colspan="2" style="text-align: center;">As it stands</td></tr><tr><td style="text-align: center; background-color: #' + team1.Colour + ';">' + predictedTeam1 + '</td><td style="text-align: center; background-color: #' + team2.Colour + ';">' + PredictedTeam2 + '</td></tr></table>';

                            headerRow.find('#headCentre').append(centreTable);
                        }
                        else {

                        }
                    }

                }

            }
        }
        else {
            if (_this.options != null && _this.options.venueId > 0) {
                _this.container.html('<p style="padding-left:24px;padding-bottom:20px;">No scores recorded at this course. Create a Game now to set a record.</p>');
            }
            else {
                // _this.container.html('<p style="padding-left:24px;padding-bottom:20px;">No leaderboard available yet, please check back later.</p>');
            }
        }
    }

    function updateTable(_this, data, topNode) {
        _this.state.oldData = _this.state.currentData.concat();
        _this.state.currentData = new Array();

        var LTopNode = data.Response.L[topNode];
        if (LTopNode.length != undefined) {
            for (var i = 0; i < LTopNode.length; i++) {
                _this.state.currentData.push(new Object());
                _this.state.currentData[_this.state.currentData.length - 1].id = LTopNode[i].ID;
                var oldRow = _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"] .row-' + LTopNode[i].ID);
                for (var k = 0; k < currentConfig(_this).columns.length; k++) {
                    if (currentConfig(_this).columns[k].isVisible) {
                        var newData = readDataRow(_this, data, topNode, i, k);
                        var cell = $(oldRow.find('td')[k]);
                        cell.html(newData);
                    }
                }
                var h = $(oldRow).quickOuterHeight();
                if ($(oldRow).find('td').length > 0 && $($(oldRow).find('td')[0]).quickOuterHeight() > h) { h = $($(oldRow).find('td')[0]).quickOuterHeight(); }
                _this.state.currentData[i].height = h;
            }
        }
        else {
            _this.state.currentData.push(new Object());
            _this.state.currentData[_this.state.currentData.length - 1].id = LTopNode.ID;
            var oldRow = _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"] .row-' + LTopNode.ID);
            for (var k = 0; k < currentConfig(_this).columns.length; k++) {
                if (currentConfig(_this).columns[k].isVisible) {
                    var newData = readDataRow(_this, data, topNode, null, k);
                    var cell = $(oldRow.find('td')[k]);
                    cell.html(newData);
                }
            }
            var h = $(oldRow).quickOuterHeight();
            if ($(oldRow).find('td').length > 0 && $($(oldRow).find('td')[0]).quickOuterHeight() > h) { h = $($(oldRow).find('td')[0]).quickOuterHeight(); }
            _this.state.currentData[0].height = h;
        }


        var table = _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]');
        _this.state.verticleOffset = $(_this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]').find('th')).quickOuterHeight();

        for (var i = 0; i < _this.state.currentData.length; i++) {
            var el = _this.state.currentData[i];
            $(table).find('tr.row-' + el.id).stop().delay(1 * i).animate({ top: _this.state.verticleOffset }, 1000, 'swing').appendTo(table);
            _this.state.verticleOffset += el.height;
        }
    }

    function readDataRow(_this, data, topNode, i, k) {
        var str = '';
        var LTopNode = data.Response.L[topNode];
        var col = currentConfig(_this).columns[k];
        if (col.dataTemplate) {
            str = col.dataTemplate;
            for (var l = 0; l < col.dataItem.split('|').length; l++) {
                var dataStr = (i != null ? LTopNode[i][col.dataItem.split('|')[l]] : LTopNode[col.dataItem.split('|')[l]]);
                //var dataStr = LTopNode[i][col.dataItem.split('|')[l]];

                if (dataStr != undefined && dataStr['#text'] != undefined) { dataStr = dataStr['#text']; }

                if (dataStr != undefined) {
                    if (dataStr.indexOf('http://') >= 0 || dataStr.indexOf('https://') >= 0 || dataStr.indexOf('.gif') >= 0 || dataStr.indexOf('.png') >= 0) {
                        dataStr = '<img src="' + dataStr + '" align="absmiddle" />';
                    }
                }
                str = str.replace('{' + l + '}', $('<div/>').html(dataStr).html());
            }
            if (str.trim() == '-<br /> HCP') { str = '-'; }
            else if (str.trim() == '<br /> HCP') { str = ''; }
        }
        else {
            var dataStr = (i != null ? LTopNode[i][col.dataItem] : LTopNode[col.dataItem]);
            if (dataStr != undefined && dataStr['#text'] != undefined) { dataStr = dataStr['#text']; }
            str = $('<div/>').html(dataStr).html();
            if (dataStr != undefined) {
                if (str.indexOf('http://') >= 0 || str.indexOf('https://') >= 0 || str.indexOf('.gif') >= 0 || str.indexOf('.png') >= 0) {
                    str = '<img src="' + str + '" class="img-responsive" />';
                }
            }
        }

        return str.replace('src="/', 'src="' + _this.options.scoresDomain + '/');
    }

    function getRowLink(event) {
        var winWidth = $(window).width();
        var winHeight = $(window).height();

        if (winWidth > 1045 && winHeight > 500 && event != undefined && event.data != undefined) {
            var _this = event.data._this;
            var encryptedId = event.data.encryptedId;
            var roundEncryptedId = event.data.roundEncryptedId;
            var leaderboardTypeId = event.data.leaderboardTypeId;
            var leaderboardConfigText = event.data.leaderboardConfigText;

            if (_this != undefined && encryptedId != undefined && encryptedId != '' && roundEncryptedId != undefined && roundEncryptedId != '' && leaderboardTypeId != undefined && leaderboardTypeId != '' && leaderboardConfigText != undefined && leaderboardConfigText != '') {
                var dialogType = 3;

                if (leaderboardTypeId == '1' || leaderboardTypeId == '7' || leaderboardTypeId == '6' || leaderboardTypeId == '9' || leaderboardTypeId == '10' || leaderboardTypeId == '11') {
                    dialogType = 5;
                }

                //if (_this.options.canEditScorecards) {
                //    ScorecardDialogOpenScorecard(decodeURIComponent(encryptedId), '', dialogType, decodeURIComponent(roundEncryptedId), leaderboardConfigText, true);
                //}
                //else {
                //    ScorecardDialogOpenScorecard(decodeURIComponent(encryptedId), '', dialogType, decodeURIComponent(roundEncryptedId), leaderboardConfigText, false);
                //}
            }
            else {
                return null;
            }
        }
        else return null;
    }

    function switchLeaderboard(_this, leaderboardIndex, isCycleSwitch) {
        var previousLeaderboardIndex = _this.state.leaderboardIndex;

        if (leaderboardIndex != null) {
            _this.state.leaderboardIndex = leaderboardIndex;
        }
        else {
            if (previousLeaderboardIndex + 1 < _this.configs.length) { _this.state.leaderboardIndex = previousLeaderboardIndex + 1; }
            else { _this.state.leaderboardIndex = 0; }
        }

        //if (_this.options.title) {
        //    if (_this.options.navigation && !_this.options.cycleLeaderboards && _this.configs.length > 1) { }
        //    else { _this.container.find('h3').text(currentConfig(_this).title); }
        //}

        if (_this.options.externalTitleSelector != '') {
            $(_this.options.externalTitleSelector).html(currentConfig(_this).title);
        }

        updateNavigation(_this);

        _this.state.verticleOffset = 0;
        _this.state.columnWidths = new Array();
        _this.state.currentData = new Array();
        _this.state.oldData = new Array();
        _this.state.rowIndex = 0;
        _this.state.rowOffset = 0;
        _this.state.lastDataRefreshTime = null;
        _this.state.isScrolling = false;
        _this.state.animating = false;
        _this.state.topWaitStartTime = 0;
        _this.state.currentScrollMode = "build";
        window.clearInterval(_this.state.intervalId);

        if (_this.state.scrollIntervalId > 0) { window.clearInterval(_this.state.scrollIntervalId); _this.state.scrollIntervalId = 0; }
        if (_this.state.scrollTopWaitId > 0) { window.clearTimeout(_this.state.scrollTopWaitId); _this.state.scrollTopWaitId = 0; }
        if (_this.state.scrollBottomWaitId > 0) { window.clearTimeout(_this.state.scrollBottomWaitId); _this.state.scrollBottomWaitId = 0; }

        for (var i = 0; i < scrollIntervals.length; i++) {
            clearInterval(scrollIntervals[i]);
        }
        scrollIntervals = [];

        for (var i = 0; i < scrollTimeouts.length; i++) {
            clearTimeout(scrollTimeouts[i]);
        }
        scrollTimeouts = [];

        var prevTable = _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + previousLeaderboardIndex + '"]');
        if ($(prevTable).find('tbody').css('top') != '0px' && $(prevTable).find('tbody').css('top') != '-0px' && $(prevTable).find('tbody').css('top') != '') {
            $(prevTable).find('tbody').css('top', '0px');
            $(prevTable).find('thead, tbody').css('position', '');
        }

        var newTable = _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]');
        if ($(newTable).find('tbody').css('top') != '0px' && $(newTable).find('tbody').css('top') != '-0px' && $(newTable).find('tbody').css('top') != '') {
            $(newTable).find('tbody').css('top', '0px');
            $(newTable).find('thead, tbody').css('position', '');
        }

        $(_this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + previousLeaderboardIndex + '"]')).hide();
        $(_this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]')).show();
        _this.container.attr('style', '');

        //_this.container.find('.leaderboardScrollBottom').hide();
        start(_this);
        updateWidth();

        _this.container.find('.vpar-leaderboardOverlay').fadeOut();
    }

    function setupScroll(_this) {
        if (_this.state.scrollTopWaitId > 0) { window.clearTimeout(_this.state.scrollTopWaitId); _this.state.scrollTopWaitId = 0; }
        if (_this.state.scrollIntervalId > 0) { window.clearInterval(_this.state.scrollIntervalId); _this.state.scrollIntervalId = 0; }
        if (_this.state.scrollBottomWaitId > 0) { window.clearTimeout(_this.state.scrollBottomWaitId); _this.state.scrollBottomWaitId = 0; }

        _this.state.currentScrollMode = "topWait";
        _this.state.scrollTopWaitId = setTimeout(function () { scrollData(_this); }, _this.options.scrollTopWait);
        _this.state.topWaitStartTime = new Date();
        scrollTimeouts.push(_this.state.scrollTopWaitId);
    }

    function scrollData(_this) {
        if (currentConfig(_this) != undefined && currentConfig(_this).columns != undefined && currentConfig(_this).columns.length > 0) {
            _this.state.topWaitStartTime = 0;
            var table = _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]');

            scrollRows(_this);

            //var timePassed = 0;

            //var rowOffset = 0;
            //for (var i = 0; i < hiddenRows; i++) {
            //    rowOffset = rowOffset + _this.state.currentData[i].height;
            //    $(table).find('tbody').animate({ top: '-' + rowOffset + 'px' }, _this.options.scrollRate);
            //    timePassed += _this.options.scrollRate;

            //    if (i + 1 == hiddenRows) {
            //        _this.container.find('.leaderboardScrollBottom').delay(timePassed - _this.options.scrollRate).fadeOut(500).delay(_this.options.scrollRate + 1000).fadeIn(500);
            //    }
            //}

            //_this.container.find('.leaderboardScrollTop').css('top', theadHeight - 1 + 'px').fadeIn(500);

            //$(table).find('tbody').delay(1000).animate({ top: '0px' }, 1000, function () {
            //    _this.container.find('.leaderboardScrollTop').fadeOut(500);
            //    $(table).find('tbody').finish();
            //    _this.container.find('.leaderboardScrollTop').finish();
            //    _this.container.find('.leaderboardScrollBottom').finish();
            //    $(table).find('thead, tbody').css('position', '');
            //    if (!_this.state.needsDataRefresh) {
            //        setupScroll(_this);
            //    }
            //    _this.state.isScrolling = false;
            //});
        }
        else if (_this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]').find('.scroll-pane-matchplay').length > 0) {
            // Matchplay
            _this.state.isScrolling = true;
            var table = _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]').find('.leaderboardMatchplayBody');

            var hiddenRows = 0;
            var tbodyHeight = 0;

            $(table).find('tbody tr').each(function () {
                tbodyHeight += $(this).height();
                if (tbodyHeight > _this.options.height) {
                    hiddenRows++;
                }
            });

            var timePassed = 0;

            var rowOffset = 0;

            for (var i = 0; i <= hiddenRows; i++) {
                rowOffset = rowOffset + $($(table).find('tbody tr')[i]).height();
                _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]').find('.scroll-pane-matchplay').animate({ 'margin-top': '-' + rowOffset + 'px' }, _this.options.scrollRate);
                timePassed += _this.options.scrollRate;

                if (i == hiddenRows) {
                    _this.container.find('.leaderboardScrollBottom').delay(timePassed - _this.options.scrollRate).fadeOut(500).delay(_this.options.scrollRate + 1000).fadeIn(500);
                }
            }

            _this.container.find('.leaderboardScrollTop').css('top', _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]').find('.leaderboardMatchplayHeader').height() + 'px').fadeIn(500);

            _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]').find('.scroll-pane-matchplay').delay(1000).animate({ 'margin-top': '0px' }, 1000, function () {
                _this.container.find('.leaderboardScrollTop').fadeOut(500);
                _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]').find('.scroll-pane-matchplay').finish();
                _this.container.find('.leaderboardScrollTop').finish();
                _this.container.find('.leaderboardScrollBottom').finish();
                if (!_this.state.needsDataRefresh) {
                    setupScroll(_this);
                }
                _this.state.isScrolling = false;
            });
        }
    }

    function scrollRows(_this) {
        _this.state.currentScrollMode = "scrollingRows";
        calculateHiddenRows(_this);

        var table = _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]');
        _this.state.isScrolling = true;
        $(table).find('thead, tbody').css('position', 'absolute');
        if (_this.state.hiddenRowsBottom > 0) {
            _this.state.scrollIntervalId = setInterval(function () {
                if (_this.state.hiddenRowsBottom > 0) {
                    _this.state.rowOffset = _this.state.rowOffset + _this.state.currentData[_this.state.hiddenRowsTop].height;
                    _this.state.animating = true;
                    $(table).find('tbody').animate({ top: '-' + _this.state.rowOffset + 'px' }, _this.options.scrollRate, function () { _this.state.animating = false; });
                    _this.state.hiddenRowsTop++;
                    _this.state.hiddenRowsBottom--;
                }
                else {
                    window.clearInterval(_this.state.scrollIntervalId); _this.state.scrollIntervalId = 0;
                    _this.state.currentScrollMode = "bottomWait";
                    _this.state.scrollBottomWaitId = setTimeout(function () {
                        endScroll(_this);
                    }, (_this.options.scrollTopWait / 2));
                    _this.state.bottomWaitStartTime = new Date();
                    scrollTimeouts.push(_this.state.scrollBottomWaitId);
                }
            }, _this.options.scrollRate);

            refreshIntervals.push(_this.state.scrollIntervalId);
            scrollIntervals.push(_this.state.scrollIntervalId);
        }
        else if (_this.state.hiddenRowsTop > 0) {
            if (_this.state.scrollTopWaitId > 0) { window.clearTimeout(_this.state.scrollTopWaitId); _this.state.scrollTopWaitId = 0; }
            if (_this.state.scrollIntervalId > 0) { window.clearInterval(_this.state.scrollIntervalId); _this.state.scrollIntervalId = 0; }
            if (_this.state.scrollBottomWaitId > 0) { window.clearTimeout(_this.state.scrollBottomWaitId); _this.state.scrollBottomWaitId = 0; }
            endScroll(_this);
        }
        else {
            _this.state.rowOffset = 0;
        }
    }

    function endScroll(_this) {
        _this.state.rowOffset = 0;
        _this.state.bottomWaitStartTime = 0;
        if (_this.state.scrollBottomWaitId > 0) { clearTimeout(_this.state.scrollBottomWaitId); _this.state.scrollBottomWaitId = 0; }
        var table = _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]');
        if ($(table).find('tbody').css('top') != '0px' && $(table).find('tbody').css('top') != '-0px') {
            _this.state.animating = true;
            $(table).find('tbody').animate({ top: '-' + _this.state.rowOffset + 'px' }, { duration: _this.options.scrollRate, complete: function () { _this.state.animating = false; $(table).find('thead, tbody').css('position', ''); } });
            _this.state.isScrolling = false;
            _this.state.hiddenRowsTop = 0;
            setupScroll(_this);
        }
    }

    function calculateHiddenRows(_this) {
        if (currentConfig(_this) != undefined && currentConfig(_this).columns != undefined && currentConfig(_this).columns.length > 0) {
            var table = _this.container.find('.vpar-leaderboardTable[data-leaderboard-index="' + _this.state.leaderboardIndex + '"]');
            _this.state.headerRowHeight = 0;
            $(table).find('thead tr').each(function () {
                _this.state.headerRowHeight += $(this).height();
            });

            _this.state.hiddenRowsBottom = 0;
            _this.state.hiddenRowsTop = 0;
            var tbodyHeight = 0;
            $(table).find('tbody tr').each(function () {
                tbodyHeight += $(this).height();

                if (tbodyHeight <= _this.state.rowOffset) {
                    _this.state.hiddenRowsTop++;
                }
                else if ((tbodyHeight - _this.state.rowOffset) > (_this.options.height - _this.state.headerRowHeight)) {
                    _this.state.hiddenRowsBottom++;
                }
            });
        }
    }

    function updateWidth() {
        try {
            $('.vpar-leaderboardTable').each(function () {
                var table = $(this);

                if ($(table).find('.leaderboardMatchplay').length == 0) {
                    if ($(table).outerWidth() < 768) {
                        $(table).addClass('smallLeaderboard');
                        $(table).find(".col-thumbnail").css('display', 'none').css('height', 'auto');
                        $(table).find(".col-thumbnail img").css('display', 'none');
                    }
                    else { $(table).find(".col-thumbnail").css('display', ''); }

                    // Remove table rows position and set each row's width to auto
                    $(table).find('tr').css('position', '').css('width', 'auto');

                    // Set table width to auto
                    $(table).css('width', $(this.parentElement.parentElement).width());

                    // Set each row's width to auto
                    $(table).find('tr td, tr th').css({ minWidth: 'auto' });

                    var columnWidths = new Array();

                    $(table).find('tr:first-child th').each(function () { columnWidths.push($(this).quickOuterWidth()); });


                    $(table).find('tr').each(function () {
                        if ($(this).find('td') != undefined && $(this).find('td').length >= columnWidths.length) {
                            for (var i = 0; i < columnWidths.length; i++) {
                                var cell = $($(this).find('td')[i]);
                                if (columnWidths[i] < cell.quickOuterWidth()) {
                                    columnWidths[i] = cell.quickOuterWidth();
                                }
                            }
                        }
                    });

                    $(table).find('tr td, tr th').each(function () {
                        $(this).css({ minWidth: columnWidths[$(this).index()] });
                    });

                    // Set table width
                    $(table).width($(table).outerWidth());

                    $(table).height($(table).quickOuterHeight());

                    // Set table cells position to absolute
                    $(table).find('tr').css('position', 'absolute');
                }
            });
        }
        catch (e) {
            //alert("Error");
        }
    }

    function shouldHideColumns(table) {
        var _shouldHideColumns = ($(table).outerWidth() > $(".vpar-leaderboard").outerWidth());

        if (!_shouldHideColumns) {
            var columnWidths = new Array();
            $(table).find('tr:first-child th').each(function () { columnWidths.push($(this).outerWidth()); });

            $(table).find('tr').each(function () {
                if ($(this).find('td') != undefined && $(this).find('td').length >= columnWidths.length) {
                    for (var i = 0; i < columnWidths.length; i++) {
                        if (columnWidths[i] < $($(this).find('td')[i]).outerWidth()) {
                            _shouldHideColumns = true;
                        }
                    }
                }
            });
        }

        return _shouldHideColumns;
    }

}(jQuery));


function replaceBetween_LB(s, start, end, what) {
    return s.substring(0, start) + what + s.substring(end);
};

jQuery.fn.quickOuterWidth = function () {
    var elem = this.get(0);
    if (window.getComputedStyle) {
        var computedStyle = window.getComputedStyle(elem, null);
        return elem.offsetWidth + (parseInt(computedStyle.getPropertyValue('margin-left'), 10) || 0) + (parseInt(computedStyle.getPropertyValue('margin-right'), 10) || 0);
    } else {
        return elem.offsetWidth + (parseInt(elem.currentStyle["marginLeft"]) || 0) + (parseInt(elem.currentStyle["marginRight"]) || 0);
    }
}
jQuery.fn.quickOuterHeight = function () {
    var elem = this.get(0);
    if (window.getComputedStyle) {
        var computedStyle = window.getComputedStyle(elem, null);
        return elem.offsetHeight + (parseInt(computedStyle.getPropertyValue('margin-left'), 10) || 0) + (parseInt(computedStyle.getPropertyValue('margin-right'), 10) || 0);
    } else {
        return elem.offsetHeight + (parseInt(elem.currentStyle["marginLeft"]) || 0) + (parseInt(elem.currentStyle["marginRight"]) || 0);
    }
}